import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { ColumnType, UrlSearch } from '@/utils/types';
import {
  DataGridTable,
  EmptyFilterView,
  LoadingIndicator,
  Pagination,
  ResizeGroupPanel,
  TablePagination,
  Typography,
} from '@/components';
import { AutomationCell, DateTimeCell, TicketLinkCell } from '@/components/Table';
import { DetailTicketContent } from './DetailTicketContent';
import { userStore } from '@/store';
import { useMainStore } from '../stores/useMainStore';
import { Filter } from './Filter';
import { EmptyLogView } from './EmptyLogView';
import { getMatch } from '@/utils';
import styles from './TaskLog.module.css';
import CreateTicketModal from './CreateTicketModal';

const PAGE_NAME = 'TaskLog';

const TaskLog = () => {
  const {
    accountStore,
    taskLogStore,
    detailStore,
    tableStore,
    filterStore,
    createTaskStore,
    workflowStore,
  } = useMainStore();
  const isDataLoading = !userStore.states?.length || !accountStore.allAccounts?.length;

  const navigate = useNavigate();

  function setTicketIdParam(ticketId) {
    const pageParams = new URLSearchParams(window.location.search);
    pageParams.set(UrlSearch.ticket, ticketId);
    pageParams.set(UrlSearch.activeTicket, ticketId);

    detailStore.setSelectedTicket(ticketId);

    navigate({
      search: pageParams.toString(),
    });
  }

  const onClose = useCallback(() => {
    const pageParams = new URLSearchParams(window.location.search);
    pageParams.delete(UrlSearch.ticket);
    pageParams.delete(UrlSearch.activeTicket);

    detailStore.setSelectedTicket();

    navigate({
      search: pageParams.toString(),
    });
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const ticketId = Number(searchParams.get(UrlSearch.ticket));
    const activeId = Number(searchParams.get(UrlSearch.activeTicket));

    if (!isDataLoading && ticketId) {
      detailStore.setSelectedTicket(ticketId, activeId);
    } else {
      detailStore.setSelectedTicket(null);
    }
  }, [isDataLoading]);

  const isLoading = taskLogStore.isLoading || accountStore.isLoading;

  if (
    !isLoading &&
    taskLogStore.parsedLogs?.length === 0 &&
    filterStore.allFilteredCount === 0 &&
    !filterStore.search
  ) {
    return <EmptyLogView />;
  }

  const columns = [
    {
      field: ColumnType.id,
      headerName: 'Ticket ID',
      width: 80,
      renderCell: (params) => (
        <TicketLinkCell
          column={ColumnType.id}
          link={params.row.ticketUrl}
          value={params.row[ColumnType.id]}
          showCopy={tableStore.hoveredRow === params.row[ColumnType.id]}
        />
      ),
    },
    {
      field: ColumnType.state,
      headerName: 'State',
      width: 120,
      renderCell: (params) => (
        <Typography variant="body2" className={styles.truncatedText}>
          {params.row[ColumnType.state]}
        </Typography>
      ),
    },
    {
      field: ColumnType.accountName,
      headerName: 'Account Name',
      width: 200,
      renderCell: (params) => (
        <Typography variant="body2" className={styles.truncatedText}>
          {params.row[ColumnType.accountName]}
        </Typography>
      ),
    },
    {
      field: ColumnType.intentType,
      headerName: 'Intent Type',
      width: 180,
      renderCell: (params) => (
        <Typography variant="body2" className={styles.truncatedText}>
          {params.row[ColumnType.intentType]}
        </Typography>
      ),
    },
    {
      field: ColumnType.title,
      headerName: 'Title',
      width: 300,
      renderCell: (params) => (
        <Typography
          variant="body2"
          className={styles.truncatedText}
          match={getMatch(params.row[ColumnType.title], filterStore.filteredSearch)}
        >
          {params.row[ColumnType.title]}
        </Typography>
      ),
    },
    {
      field: ColumnType.createdDate,
      headerName: 'Created Date',
      width: 120,
      renderCell: (params) => (
        <DateTimeCell
          id={`${PAGE_NAME}_createdDate`}
          column={ColumnType.createdDate}
          value={params.row[ColumnType.createdDate]}
        />
      ),
    },
    {
      field: ColumnType.lastUpdated,
      headerName: 'Last Updated',
      width: 120,
      renderCell: (params) => (
        <DateTimeCell
          id={`${PAGE_NAME}_lastUpdated`}
          column={ColumnType.lastUpdated}
          value={params.row[ColumnType.lastUpdated]}
        />
      ),
    },
    {
      field: ColumnType.automation,
      headerName: 'Automation',
      width: 150,
      renderCell: (params) => (
        <AutomationCell automation={params.row[ColumnType.automation]} isAdminUser />
      ),
    },
  ];

  return (
    <div className={styles.content} id={`${PAGE_NAME}_container`}>
      <div className={styles.container} id={`${PAGE_NAME}_contentContainer`}>
        {accountStore.isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <Filter />
            <ResizeGroupPanel
              direction="vertical"
              firstPanel={
                <>
                  {taskLogStore.isLoading && <LoadingIndicator />}
                  {!isLoading && (
                    <div className={styles.tableBlock} id={`${PAGE_NAME}_tableBlock`}>
                      {taskLogStore.parsedLogs.length === 0 &&
                      (filterStore.allFilteredCount > 0 || filterStore.search) ? (
                        <EmptyFilterView
                          noFilterResult={filterStore.allFilteredCount > 0}
                          onClick={() =>
                            filterStore.allFilteredCount > 0
                              ? filterStore.clearFilter()
                              : filterStore.setSearch('')
                          }
                        />
                      ) : (
                        <>
                          <DataGridTable
                            rows={taskLogStore.parsedLogs}
                            columns={columns}
                            onRowClick={(params) => setTicketIdParam(params.id)}
                            hideFooter
                            classNameBox={styles.tableContainer}
                            classes={{
                              scrollbar: styles.scrollArea,
                              virtualScroller: styles.customVirtualScroller,
                              cell: styles.customCell,
                              selected: styles.customSelected,
                              row: styles.customRow,
                            }}
                          />
                          <div className={styles.pagination}>
                            <TablePagination
                              label="View Per Page"
                              value={tableStore.rowsPerPage}
                              id={`${PAGE_NAME}_paginationDropdown`}
                              options={[10, 20, 50, 100, 1000]}
                              onChangeRowsPerPage={(v) => tableStore.setRowsPerPage(v)}
                            />
                            <Pagination
                              count={taskLogStore.pageCount}
                              page={tableStore.pageNumber}
                              onChange={(e, v) => tableStore.setPageNumber(v)}
                              id={`${PAGE_NAME}_pagination`}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </>
              }
              showLastPanel={detailStore.isShowTicket}
            >
              <DetailTicketContent onClose={onClose} />
            </ResizeGroupPanel>
            <CreateTicketModal
              open={createTaskStore.isCreateMode}
              handleClose={() => {
                workflowStore.reset();
                createTaskStore.setCreateMode(null);
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default observer(TaskLog);
