import { useState } from 'react';
import { BASE_ROUTES, UrlSearch } from '@/utils/types';
import { MenuItem, MoreButtonPopover } from '@/components';
import './SideMenu.css';

const SideMenuItemPopover = ({ orgId, isHovered, isChild }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleClosePopover = () => setIsPopoverOpen(false);

  const handleNavigation = (route) => {
    window.location.href = route;
    handleClosePopover();
  };

  const buildUrl = (baseRoute, params) => `${baseRoute}?${new URLSearchParams(params).toString()}`;

  const menuItems = [
    {
      label: 'Configuration',
      route: buildUrl(BASE_ROUTES.organizations, {
        [UrlSearch.organization]: orgId,
      }),
    },
    {
      label: 'Members',
      route: buildUrl(BASE_ROUTES.users, {
        [UrlSearch.organization]: orgId,
        organizationId: orgId,
      }),
    },
    {
      label: 'Schedules',
      route: buildUrl(BASE_ROUTES.schedules, {
        [UrlSearch.organization]: orgId,
      }),
    },
  ];

  return (
    <MoreButtonPopover
      isHovered={isHovered}
      popoverClassName={`sidebarPopover${isChild ? ' is-child' : ''}`}
      tooltipText="Organization Options"
      isPopoverOpen={isPopoverOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <div className="popoverContainer">
        {menuItems.map(({ label, route }) => (
          <MenuItem key={label} onClick={() => handleNavigation(route)}>
            {label}
          </MenuItem>
        ))}
      </div>
    </MoreButtonPopover>
  );
};

export default SideMenuItemPopover;
