import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { TableCell } from '@mui/material';
import cx from 'clsx';

import styles from './OrganizationCell.module.css';

const OrganizationCell = ({ isChild, isExpanded, label, onToggleExpand, level }) => {

  return (
    <TableCell className={styles.treeCell}>
      <div
        className={styles.navItemContainer}
        style={{
          paddingLeft: level < 0 ? 0 : (isChild ? 0 : 20) + level * 15,
        }}
        id="OrganizationTree_sideMenuItem"
      >
        {isChild && (
          <span className={styles.expandIcon} onClick={onToggleExpand}>
            {isExpanded ? '⊟' : '⊞'}
          </span>
        )}
        <div
          className={cx(styles.labelWrapper, {
            [styles.expandWrapper]: isChild,
          })}
        >
          <span className={styles.label}>{label}</span>
        </div>
      </div>
    </TableCell>
  );
};

OrganizationCell.propTypes = {
  isChild: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isAllAccount: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onToggleExpand: PropTypes.func,
};

OrganizationCell.defaultProps = {
  isChild: false,
  isExpanded: false,
  isAllAccount: false,
  isSelected: false,
  onToggleExpand: undefined,
};

export default OrganizationCell;
