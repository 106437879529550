import { Fragment, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { Typography } from '@/components';
import { ColumnType } from '@/utils/types';
import { useSettingsStore } from '@/store';
import { ScheduleTableRow } from './ScheduleTableRow';
import styles from './ScheduleTable.module.css';
//import { useStyles } from './styles';

const PAGE_NAME = 'ScheduleTable';

const ScheduleTable = () => {
  const { scheduleStore } = useSettingsStore();
  //const styles = useStyles();
  const tableRef = useRef(null);
  const [isScrollVisible, setIsScrollVisible] = useState(false);

  useEffect(() => {
    if (tableRef.current) {
      if (tableRef.current.scrollHeight > tableRef.current.clientHeight + 10) {
        setIsScrollVisible(true);
      } else {
        setIsScrollVisible(false);
      }
    }
  }, [scheduleStore.filteredScheduleList]);

  return (
    <div className={styles.content} id={`${PAGE_NAME}_container`}>
      <div className={styles.container} id={`${PAGE_NAME}_contentContainer`}>
        <div
          className={styles.tableBlock}
          id={`${PAGE_NAME}_tableBlock`}
          style={{ paddingRight: isScrollVisible ? 0 : 8 }}
        >
          {scheduleStore.filteredScheduleList.length > 0 && (
            <TableContainer className={styles.tableContainer} ref={tableRef}>
              <Table stickyHeader size="small" id={`${PAGE_NAME}_table`}>
                <TableHead id={`${PAGE_NAME}_table_head`}>
                  <TableRow>
                    {scheduleStore.headerColumns.map(
                      ({ label, align, sortKey, key, width }, idx) => (
                        <TableCell
                          key={key}
                          align={align}
                          className={cx(styles.headCell, {
                            [styles.headCellLast]: idx === scheduleStore.headerColumns.length - 1,
                          })}
                          style={{ minWidth: width }}
                        >
                          {sortKey ? (
                            <TableSortLabel
                              active={scheduleStore.isActiveSort(sortKey)}
                              direction={scheduleStore.getSortDirection(sortKey)}
                              IconComponent={ArrowDropDownIcon}
                              classes={{ icon: styles.sortIcon }}
                              onClick={() => scheduleStore.toggleSort(sortKey)}
                            >
                              <Typography variant="button-small">{label}</Typography>
                            </TableSortLabel>
                          ) : (
                            <Typography variant="button-small">{label}</Typography>
                          )}
                        </TableCell>
                      ),
                    )}
                  </TableRow>
                </TableHead>

                <TableBody id={`${PAGE_NAME}_table_body`}>
                  {scheduleStore.filteredScheduleList.map((schedule, index) => (
                    <Fragment key={schedule[ColumnType.id]}>
                      <ScheduleTableRow
                        schedule={schedule}
                        isLastRow={index === scheduleStore.filteredScheduleList.length - 1}
                      />
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(ScheduleTable);
