import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { cloneDeep, isEqual, orderBy, trim } from 'lodash';
import { organizationApi } from '@/api';
import { FilterType } from '@/utils/constants';
import {
  AssignType,
  AutomationStatusIcons,
  BASE_ROUTES,
  ColumnType,
  RequestTypes,
} from '@/utils/types';
import { userStore } from '@/store';
import { setError } from '@/utils/errors';
import { accountStore } from '@/store/AccountStore';
import { mapToStatusOption } from '@/utils';

export const InitFilter = {
  [FilterType.status]: {
    key: ColumnType.status,
    label: 'Status',
    values: [],
    selectedValues: [],
  },
  [FilterType.account]: {
    key: ColumnType.accountName,
    label: 'Account',
    values: [],
    selectedValues: [],
    hasSearchBar: true,
  },
  [FilterType.service]: {
    key: ColumnType.intentType,
    label: 'Service',
    values: [],
    selectedValues: [],
    hasSearchBar: true,
  },
  [FilterType.type]: {
    key: ColumnType.objectType,
    label: 'Root/Children',
    values: [
      { value: RequestTypes.all, label: 'All' },
      { value: RequestTypes.request, label: 'Root Requests' },
      { value: RequestTypes.child, label: 'Child Tasks' },
    ],
    selectedValues: [RequestTypes.all],
  },
  [FilterType.owner]: {
    key: ColumnType.ownedByName,
    label: 'Owner',
    values: [],
    selectedValues: [],
    hasSearchBar: true,
  },
  [FilterType.assign]: {
    key: ColumnType.assignedName,
    label: 'Assignee',
    values: [],
    selectedValues: [],
    hasSearchBar: true,
  },
  [FilterType.automation]: {
    key: ColumnType.automation,
    label: 'Automation',
    values: Object.keys(AutomationStatusIcons).map((value) => ({
      value,
      label: value,
    })),
    selectedValues: [],
  },
  [FilterType.updatedAfter]: {
    key: ColumnType.lastUpdated,
    label: 'Updated After',
    values: [],
    selectedValues: [],
  },
  [FilterType.createdAfter]: {
    key: ColumnType.createdOn,
    label: 'Created After',
    values: [],
    selectedValues: [],
  },
};

const DEFAULT_STATES = ['open', 'blocked', 'needs approval'];

export class FilterStore {
  filters = cloneDeep(InitFilter);
  search = '';
  filteredSearch = '';

  constructor() {
    makeObservable(this, {
      setFilters: action,
      filters: observable,
      updateAllSelectedValues: action,
      filteredResult: computed,
      allFilteredCount: computed,
      search: observable,
      setSearch: action,
      filteredSearch: observable,

      updateSelectedValues: action,
      clearFilter: action,

      getDefaultStatusSelected: computed,
      applyDefaultStatusFilter: action,

      filtersQuery: computed,
      selectedFilters: computed,

      fetchFilters: action,
    });
  }

  get filtersQuery() {
    const query = Object.keys(this.filteredResult).reduce((res, filterKey) => {
      const value = this.filteredResult[filterKey]?.selectedValues;
      if (value.length === 0) return res;
      return { ...res, [filterKey]: value };
    }, {});
    return query;
  }

  getMyStatus() {
    return this.filters?.[FilterType.status]?.values
      .filter((item) => ['open', 'blocked', 'needs approval'].includes(item.label.toLowerCase()))
      .map((item) => item.value);
  }

  get getDefaultStatusSelected() {
    const defaultStatus = this.filters?.[FilterType.status]?.values
      .filter((item) => DEFAULT_STATES.includes(item.label.toLowerCase()))
      .map((item) => item.value);
    return Object.keys(this.filters).every((key) => {
      if (key === FilterType.status) {
        return isEqual(this.filters[key].selectedValues, defaultStatus);
      } else {
        return this.filters[key].selectedValues?.length === 0;
      }
    });
  }

  applyDefaultStatusFilter() {
    if (
      Object.keys(this.filters).every((key) => {
        return this.filters[key].selectedValues?.length === 0;
      })
    ) {
      const defaultStatus = this.filters?.[FilterType.status]?.values
        .filter((item) => DEFAULT_STATES.includes(item.label.toLowerCase()))
        .map((item) => item.value);
      this.updateSelectedValues(FilterType.status, defaultStatus);
    }
  }

  getOwnerName(assignId) {
    const findItem = this.filteredResult[FilterType.assign].values.find(
      (item) => item.value === assignId,
    );
    return assignId ? findItem?.label ?? 'None' : AssignType.unassigned;
  }

  setSearch(value) {
    this.search = trim(value);
  }

  setFilters(lookupsData) {
    const { assignees, states, services, automation } = lookupsData;
    userStore.setAssignees(assignees);
    this.filters[FilterType.account].values = orderBy(
      accountStore.arrangedAccounts
        .filter(({ path }) => path.includes(accountStore.selectedAccount?.id))
        .map(({ id, name, path }) => ({ value: id, label: name, path })),
      'label',
    );
    this.filters[FilterType.status].values = states.map((state) =>
      mapToStatusOption(state, state.id),
    );
    // Assign
    this.filters[FilterType.assign].values = assignees.map(
      (user) => userStore.assignOptions.find((assign) => assign.value === user.value) || user,
    );
    // Owner
    this.filters[FilterType.owner].values = assignees.map(
      (user) => userStore.ownerOptions.find((owner) => owner.value === user.value) || user,
    );
    // Service
    this.filters[FilterType.service].values = services;
    this.filters[FilterType.automation].values = automation;
  }

  get filteredResult() {
    const filteredFilters = this.filters;
    const result = Object.keys(filteredFilters).reduce((result, filterKey) => {
      const values =
        filterKey === FilterType.service
          ? filteredFilters[filterKey].values
          : orderBy(filteredFilters[filterKey].values, (item) => item.label?.toLowerCase(), 'asc');

      return {
        ...result,
        [filterKey]: { ...filteredFilters[filterKey], values },
      };
    }, {});
    return result;
  }

  async clearFilter() {
    const newFilters = Object.keys(this.filters).reduce(
      (result, key) => ({
        ...result,
        [key]: { ...this.filters[key], selectedValues: [] },
      }),
      {},
    );

    this.filters = newFilters;
    this.applyDefaultStatusFilter();
    this.search = '';
  }

  updateAllSelectedValues(value) {
    this.filters[FilterType.account].selectedValues = value[FilterType.account];
    this.filters[FilterType.automation].selectedValues = value[FilterType.automation];
    this.filters[FilterType.status].selectedValues = value[FilterType.status];
    this.filters[FilterType.service].selectedValues = value[FilterType.service];
    this.filters[FilterType.owner].selectedValues = value[FilterType.owner];
    this.filters[FilterType.assign].selectedValues = value[FilterType.assign];
  }

  updateFilterSelectedValues(value) {
    Object.keys(value).forEach((filterKey) => {
      if (this.filters[filterKey]) {
        this.filters[filterKey].selectedValues = value[filterKey].selectedValues;
      }
    });
  }

  updateSelectedValues(key, value) {
    this.filters[key].selectedValues = value;
  }

  getSelectedCount(type) {
    return this.filteredResult[type]?.selectedValues?.length ?? 0;
  }

  get allFilteredCount() {
    const count = Object.keys(this.filteredResult).reduce(
      (acc, key) => acc + this.getSelectedCount(key),
      0,
    );
    return count;
  }

  get pageSettings() {
    return userStore.getPageSettings(BASE_ROUTES.main);
  }

  get selectedFilters() {
    return Object.keys(this.filteredResult);
  }

  // NOTE: Fetch filters
  async fetchFilters(organizationId) {
    if (!organizationId) return;
    try {
      await userStore.fetchLookupsInfo(organizationId);
      runInAction(() => {
        this.setFilters(userStore.lookupsInfo);
        if (!this.pageSettings.path) this.applyDefaultStatusFilter();
      });
    } catch (err) {
      setError(err, false, 'Get filters failed');
    }
  }

  dispose() {}
}
