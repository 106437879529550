import { Button, LoadingIndicator, TreeListDropdown, Typography } from '@/components';
import CreateForm from './CreateForm';

import styles from './CreateTicketModal.module.css';
import { userStore, withStore } from '@/store';
import { useMainStore } from '../../stores/useMainStore';
import { SECOND } from '@/utils/constants';
import { showErrorNotification } from '@/utils';
import { NotificationText, UrlSearch } from '@/utils/types';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PAGE_NAME = 'CreateTicketPage';

const CreateTicketPage = ({ open, onClose }) => {
  const navigate = useNavigate();
  const { createTaskStore, detailStore, workflowStore } = useMainStore();
  const { workflowId } = createTaskStore;

  const gotoNext = async () => {
    if (!userStore.currentUser.isContributor)
      showErrorNotification(NotificationText.createTicketPermissionWarning, {
        autoHideDuration: SECOND,
      });
    else {
      createTaskStore.setShowForm(true);
    }
  };

  const handleGoBack = (refreshPage) => {
    createTaskStore.reset(refreshPage);
    workflowStore.reset();
    createTaskStore.setShowForm(false);
  };

  const handleCancel = () => {
    createTaskStore.reset();
    workflowStore.reset();
    createTaskStore.setShowForm(false);
    onClose();
  };

  const handleClose = (ticketId) => {
    if (ticketId) {
      const pageParams = new URLSearchParams(window.location.search);
      pageParams.set(UrlSearch.ticket, ticketId);
      pageParams.set(UrlSearch.activeTicket, ticketId);
      createTaskStore.setCreateMode(null);
      workflowStore.reset();

      detailStore.setSelectedTicket(ticketId);

      navigate({
        search: pageParams.toString(),
      });
    }
  };

  useEffect(() => {
    if (createTaskStore.showForm) {
      createTaskStore.setQuantity(1);
      createTaskStore.updateData({ workflow: createTaskStore.workflowInfo?.workflow });
    }
  }, [createTaskStore.showForm]);

  if (createTaskStore.isLoading) {
    return (
      <div id={`${PAGE_NAME}_loading`} className={styles.loadingContainer}>
        <LoadingIndicator />
      </div>
    );
  }

  if (workflowId && createTaskStore.showForm)
    return <CreateForm goBack={handleGoBack} onClose={handleCancel} handleClose={handleClose} />;

  return (
    <div id={`${PAGE_NAME}_container`} className={styles.contentContainer}>
      <Typography variant="h4">Create Service</Typography>

      <div>
        <TreeListDropdown
          label="Service*"
          placeholder="Select Service"
          treeList={userStore.workflowTreeList}
          allItems={userStore.workflowConfigList}
          selectedValues={workflowId ? [workflowId] : []}
          className={styles.orgsDropdown}
          onChange={(value) => createTaskStore.setWorkflow(value[0])}
        />
      </div>

      <div id={`${PAGE_NAME}_buttonContainer`} className={styles.buttonGroup}>
        <Button
          variant="primary"
          tooltipText={
            !userStore.currentUser.isContributor
              ? NotificationText.createTicketPermissionWarning
              : ''
          }
          disabled={!workflowId}
          onClick={gotoNext}
          id={`${PAGE_NAME}_nextButton`}
        >
          Next
        </Button>
        <Button variant="tertiary" onClick={onClose} id={`${PAGE_NAME}_cancelButton`}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default withStore(observer(CreateTicketPage));
