import { useEffect } from 'react';
import { disableGlobalCursorStyles, Panel, PanelGroup } from 'react-resizable-panels';

import { ResizeHandle } from './ResizeHandle';
import { RESIZE_SAVE_KEY } from '@/utils';

import styles from './styles.module.css';
import clsx from 'clsx';

export default function ResizeGroupPanel({
  saveId = RESIZE_SAVE_KEY,
  direction = 'vertical',
  firstPanel = null,
  showFirstPanel = true,
  showLastPanel = true,
  initialFirstSize = 30,
  classes,
  children,
}) {
  const resizeHandleClass =
    direction === 'vertical' ? styles.verticalResizeHandle : styles.horizontalResizeHandle;

  useEffect(() => disableGlobalCursorStyles(), []);

  return (
    <PanelGroup autoSaveId={saveId} direction={direction} id={saveId} className={classes?.root}>
      {showFirstPanel && (
        <>
          <Panel
            id="first"
            className={clsx(styles.Panel, classes?.firstPanel)}
            collapsible
            order={1}
            defaultSize={initialFirstSize}
          >
            {firstPanel}
          </Panel>
          {showLastPanel && <ResizeHandle id={saveId} className={resizeHandleClass} />}
        </>
      )}
      {showLastPanel && (
        <Panel id="last" className={clsx(styles.Panel, classes?.lastPanel)} collapsible order={3}>
          {children}
        </Panel>
      )}
    </PanelGroup>
  );
}
