import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import './DataGridTable.css';

export default function DataGridTable({
  rows,
  columns,
  rowHeight = 21,
  columnHeaderHeight = 33,
  classNameBox='',
  ...otherProps
}) {
  return (
    <Box className={`tableContainer ${classNameBox}`}>
      <DataGrid
        rows={rows}
        columns={columns}
        rowHeight={rowHeight}
        columnHeaderHeight={columnHeaderHeight}
        disableColumnMenu
        {...otherProps}
      />
    </Box>
  );
}
