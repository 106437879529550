import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import AceEditor from 'react-ace';
import 'brace/mode/json'; // Import the JSON mode
import 'brace/theme/monokai'; // Import the Monokai theme

import { CollapseButton, IconTabs, Typography } from '@/components';
import { CONFIG_TABS } from '@/utils/constants';
import { useMainStore } from '../../stores/useMainStore';

import styles from './DetailWorkflowContent.module.css';

const JSON_LINE_HEIGHT = 17;
const JSON_EDITOR_PADDING = 28;
const tabs = [
  { label: CONFIG_TABS.form, value: true },
  { label: CONFIG_TABS.json, value: false },
];

export const DetailWorkflowContent = observer(({ content, isEditing, workflowName }) => {
  const { workflowStore, detailStore } = useMainStore();
  const [SpecificForm, setSpecificForm] = useState(null);
  const jsonStr = JSON.stringify(content, null, 2);
  const height = `${jsonStr.split('\n').length * JSON_LINE_HEIGHT + JSON_EDITOR_PADDING}px`;
  const showForm = workflowStore.showForm;
  const isCollapse = detailStore.isCollapse.configuration;

  useEffect(() => {
    if (workflowName) {
      import(`./SpecificForms/${workflowName}`)
        .then((module) => {
          setSpecificForm(() => module[workflowName]);
        })
        .catch(() => {
          setSpecificForm(null);
        });
      workflowStore.reload(content);
    }
  }, [workflowName]);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.row}>
          <Typography variant="caption-bold">Configuration</Typography>
          <CollapseButton
            isCollapse={isCollapse}
            onClick={() => detailStore.setIsCollapse({ configuration: !isCollapse })}
          />
        </div>
        <IconTabs
          activeColor=""
          tabArray={tabs}
          value={showForm}
          flexContainer={styles.row}
          onChange={(_, newValue) => workflowStore.setShowForm(newValue)}
        />
      </div>
      {!isCollapse && (
        <>
          {showForm && Boolean(SpecificForm) && (
            <SpecificForm config={content} disabled={!isEditing} showWarning />
          )}
          {!showForm && (
            <AceEditor
              mode="json"
              theme="monokai"
              readOnly
              name="json-editor"
              value={jsonStr}
              editorProps={{ $blockScrolling: true }}
              showPrintMargin={false}
              showGutter={false}
              highlightActiveLine
              fontSize={14}
              height={height}
              style={{ borderRadius: '4px', width: 'calc(100% - 16px)', marginLeft: '16px' }}
              enableBasicAutocompletion={false}
              enableLiveAutocompletion={false}
              enableSnippets={false}
              tabSize={2}
            />
          )}
        </>
      )}
    </>
  );
});
