import { BASE_ROUTES, ObjectType, UrlSearch } from './types';

export function getObjectType(obj) {
  if (obj.isCase || obj.externalId) return ObjectType.case;
  if (obj.isQuestion || obj.value?.includes(ObjectType.question)) return ObjectType.question;
  if (obj.isRequest) return ObjectType.request;
  return ObjectType.task;
}

export function getTicketLink(ticketId, organizationId) {
  return `${BASE_ROUTES.main}?${
    organizationId ? `${UrlSearch.organization}=${organizationId}&` : ''
  }${UrlSearch.ticket}=${ticketId}&${UrlSearch.activeTicket}=${ticketId}`;
}
