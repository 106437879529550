import { useRef, useState, useCallback, useMemo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cx from 'clsx';
import queryString from 'query-string';

import { Accordion, AccordionDetails, AccordionSummary, SearchField } from '@/components';
import SideMenuItem from './SideMenuItem';
import styles from './Sidebar.module.css';
import { useMainStore } from '@/pages/main/stores/useMainStore';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useSettingsStore } from '@/store';

const PAGE_NAME = 'SideMenu';

const Sidebar = () => {
  const { accountStore, scheduleStore } = useSettingsStore();
  const { taskLogStore } = useMainStore();

  const isLoading = taskLogStore.isLoading || accountStore.isLoading || scheduleStore.isLoading;
  const selectedOrgId = accountStore.selectedAccount?.id;
  const containerRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      !isLoading &&
      selectedOrgId &&
      accountStore.filteredAccounts.length > 0 &&
      containerRef.current
    ) {
      let top = 0;
      const findPosition = (accounts) => {
        return accounts.some((account) => {
          top += 32;
          if (account.id === selectedOrgId) {
            return true;
          }
          let result = false;
          if (account.children.length > 0 && account.isExpanded) {
            result = findPosition(account.children);
          }
          return result;
        });
      };
      findPosition(accountStore.filteredAccounts);
      if (Math.abs(containerRef.current.scrollTop - top) > containerRef.current.clientHeight - 32) {
        containerRef.current.scrollTo({
          top,
          behavior: 'smooth',
        });
      }
    }
  }, [isLoading, selectedOrgId, accountStore.filteredAccounts]);

  const handleSelect = useCallback((e, id) => {
    e.stopPropagation();

    if (id === selectedOrgId) {
      handleToggle(id);
    } else {
      navigate(
        `?${queryString.stringify({ ...queryString.parse(location.search), organization: id })}`,
      );
    }
  }, []);

  const handleToggle = useCallback((id, e) => {
    e?.stopPropagation();
    accountStore.onToggleExpandNode(id);
  }, []);

  const debouncedSetSearch = useCallback(
    debounce((newSearch) => {
      accountStore.setSearch(newSearch);
    }, 300),
    [],
  );

  const handleSearchChange = (e) => {
    const newSearch = e.target.value;
    debouncedSetSearch(newSearch);
  };

  const renderSubOrganizationItem = ({ id, name, children, isExpanded }, layer) => {
    const hasChildren = children && children.length > 0;

    return (
      <Accordion
        key={id}
        classes={{
          accordionRoot: styles.accordionRoot,
          accordionExpanded: styles.accordionExpanded,
        }}
        id={`${PAGE_NAME}_organizations_accordion`}
        expanded={!!isExpanded}
      >
        <AccordionSummary
          id={`${PAGE_NAME}_organizations_accordion_content`}
          containerClassName={cx({
            [styles.accordionSummary]: true,
            [styles.accordionSelectedSummary]: selectedOrgId === id,
          })}
          contentClassName={styles.accordionSummaryContent}
        >
          <SideMenuItem
            layer={layer}
            isChild={hasChildren}
            isExpanded={!!isExpanded}
            isSelected={selectedOrgId === id}
            orgId={id}
            label={name}
            onSelect={(e) => handleSelect(e, id)}
            onToggleExpand={(e) => handleToggle(id, e)}
            search={accountStore.search}
          />
        </AccordionSummary>
        {hasChildren && (
          <AccordionDetails className={styles.accordionDetails}>
            {children.map((subOrganization) =>
              renderSubOrganizationItem(subOrganization, layer + 1),
            )}
          </AccordionDetails>
        )}
      </Accordion>
    );
  };

  useEffect(() => {
    const params = queryString.parse(location.search);
    const orgIdFromUrl = params.organization ? Number(params.organization) : null;

    if (orgIdFromUrl && orgIdFromUrl !== selectedOrgId) {
      accountStore.onSelectAccount(orgIdFromUrl);
      handleToggle(orgIdFromUrl);
    }
  }, [location.search]);

  return (
    <div className={styles.sideContainer}>
      <div className={styles.sideContent}>
        <SearchField
          size="small"
          id="organizations-search"
          placeholder="Search"
          value={accountStore.search}
          classes={{ root: styles.searchRoot }}
          onChange={handleSearchChange}
        />
        <div id={`${PAGE_NAME}_sideMenuItem_group`} className={styles.body} ref={containerRef}>
          {accountStore.filteredAccounts.map((organization) =>
            renderSubOrganizationItem(organization, 1),
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(Sidebar);
