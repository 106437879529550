import PropTypes from 'prop-types';
import cx from 'clsx';
import MUIButton from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const mapVariationToMUIProps = (variant) =>
  ({
    xlarge: {
      color: 'primary',
      variant: 'contained',
    },
    primary: {
      color: 'primary',
      variant: 'contained',
    },
    gradient: {
      color: 'primary',
      variant: 'contained',
    },
    secondary: {
      color: 'primary',
    },
    dangerPrimary: {
      variant: 'contained',
    },
  }[variant] || {});

const useStyles = makeStyles((theme) => ({
  button: {
    fontFamily: 'Open Sans !important',
    borderRadius: '8px !important',
    boxShadow: 'none !important',
    textTransform: 'capitalize !important',
  },
  xl: {
    fontWeight: '700 !important',
    fontSize: '15px !important',
    letterSpacing: '0.46px !important',
    lineHeight: '26px !important',
    padding: '11px 26px !important',
    borderRadius: '88px !important',
  },
  large: {
    fontWeight: '700 !important',
    fontSize: '15px !important',
    letterSpacing: '0.46px !important',
    lineHeight: '26px !important',
    padding: '8px 22px !important',
  },
  medium: {
    fontWeight: '700 !important',
    fontSize: '14px !important',
    letterSpacing: '0.4px !important',
    lineHeight: '24px !important',
    padding: '6px 14px !important',
  },
  primary: {
    backgroundColor: `${theme.palette.primary.purple} !important`,
    color: `${theme.palette.secondary.contrast} !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.darkPurple} !important`,
    },
  },
  primaryDisabled: {
    backgroundColor: `${theme.palette.neutrals.lightGrey} !important`,
    color: `${theme.palette.secondary.contrast} !important`,
  },
  primaryActive: {
    backgroundColor: `${theme.palette.primary.darkPurple} !important`,
  },
  secondary: {
    backgroundColor: `#FFFFFF !important`,
    color: `${theme.palette.neutrals.grey} !important`,
    border: `2px solid ${theme.palette.neutrals.lightGrey} !important`,
    '&:hover': {
      border: `2px solid ${theme.palette.primary.purple} !important`,
    },
  },
  small: {
    fontWeight: '600 !important',
    fontSize: '13px !important',
    letterSpacing: '0.46px !important',
    lineHeight: '22px !important',
    padding: '4px 10px !important',
    borderWidth: '1px !important',    
    '&:hover': {
      borderWidth: `1px !important`,
    },
  },
  secondaryDisabled: {
    backgroundColor: `#F4F4F4 !important`,
    color: `${theme.palette.neutrals.lightGrey} !important`,
    border: `1px solid ${theme.palette.neutrals.lightGrey} !important`,
  },
  secondaryActive: {
    border: `2px solid ${theme.palette.primary.purple} !important`,
  },
  tertiary: {
    backgroundColor: 'transparent !important',
    color: `${theme.palette.primary.purple} !important`,
    '&:hover': {
      color: `${theme.palette.primary.darkPurple} !important`,
    },
  },
  tertiaryDisabled: {
    color: `${theme.palette.neutrals.lightGrey} !important`,
  },
  tertiaryActive: {
    color: `${theme.palette.primary.darkPurple} !important`,
  },
  gradient: {
    background: `${theme.palette.gradients.purple} !important`,
    color: `${theme.palette.secondary.contrast} !important`,
    '&:hover': {
      background: `${theme.palette.gradients.darkPurple} !important`,
    },
  },
  gradientDisabled: {
    background: `${theme.palette.neutrals.lightGrey} !important`,
    color: `${theme.palette.secondary.contrast} !important`,
  },
  gradientActive: {
    background: `${theme.palette.gradients.darkPurple} !important`,
  },
  xlarge: {
    background: `${theme.palette.gradients.purple} !important`,
    color: `${theme.palette.secondary.contrast} !important`,
    '&:hover': {
      background: `${theme.palette.gradients.darkPurple} !important`,
    },
  },
  xlargeDisabled: {
    background: `${theme.palette.neutrals.lightGrey} !important`,
    color: `${theme.palette.secondary.contrast} !important`,
  },
}));

const Button = (props) => {
  const { classes = {}, variant, size, isActive } = props;
  const styles = useStyles();

  const buttonClasses = {
    ...classes,
    root: cx(
      styles.button,
      {
        [styles?.[variant]]: size !== 'xlarge',
        [styles.xl]: size === 'xlarge',
      },
      {
        [styles.xlarge]: size === 'xlarge',
        [styles.large]: size === 'large',
        [styles.medium]: size === 'medium',
        [styles.small]: size === 'small',
      },
      {
        [styles.primaryActive]: isActive && variant === 'primary',
        [styles.secondaryActive]: isActive && variant === 'secondary',
        [styles.tertiaryActive]: isActive && variant === 'tertiary',
        [styles.gradientActive]: isActive && variant === 'gradient',
      },
      classes.root,
    ),
    disabled: cx(
      {
        [styles?.[`${variant}Disabled`]]: size !== 'xlarge',
        [styles.xlargeDisabled]: size === 'xlarge',
      },
      classes.disabled,
    ),
    startIcon: classes.startIcon,
  };

  const muiProps = {
    ...props,
    ...mapVariationToMUIProps(props.variant),
    classes: buttonClasses,
  };

  let height;
  if (size === 'xlarge') height = 48;
  else if (size === 'large') height = 42;
  else if (size === 'medium') height = 36;
  else height = 24;

  return <MUIButton {...muiProps} sx={{ height, ...props.style }} />;
};

Button.propTypes = {
  size: PropTypes.oneOf(['xlarge', 'large', 'medium', 'small']),
  variant: PropTypes.oneOf(['primary', 'gradient', 'secondary', 'tertiary']),
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  size: 'medium',
  variant: 'primary',
  disabled: false,
};

export default Button;
