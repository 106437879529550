import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { isEqual, sortBy } from 'lodash';
import { userApi, organizationApi } from '../api';
import {
  saveToken,
  sortMembers,
  mapToUserOption,
  saveAdminSettings,
  getAdminAppSettings,
  mapToStatusOption,
} from '../utils';
import { setError } from '../utils/errors';
import { DEFAULT_BRANDING_INFO } from '../api/constants';
import { RouterStore } from './RouterStore';
import { AssignType, NotificationText } from '@/utils/types';
import { getWorkflowConfigList } from '@/utils/staticForm';

export class UserStore {
  version = '1.0.0.0';
  currentUser = {};
  organizationId = null;
  members = [];
  assignOptions = [];
  closeTaskTrigger = false;
  isUnAuthorizedError = false;
  apiCriticalIssue = null;
  states = [];
  platformTypes = [];
  appLoaded = false;
  prevUrlParams = {};
  lastUrlLoaded = {};
  organizationLogo = null;
  organizationName = '';
  organizationHelpLink = DEFAULT_BRANDING_INFO.helpUrl;
  isRefresh = false;
  workflowConfigList = [];
  workflowTreeList = [];
  appSettings = {};
  errorAPIs = {};
  lookupsInfo = {};

  constructor() {
    makeObservable(this, {
      version: observable,
      errorAPIs: observable,
      // User parameters
      currentUser: observable,
      isWFLAdminUser: computed,
      setUser: action,
      setUserEmailEnabled: action,
      organizationId: observable,
      members: observable,
      assignOptions: observable,
      setAssignees: action,
      updateName: action,
      prevUrlParams: observable,
      setUrlParams: action,
      lastUrlLoaded: observable,
      setLastUrlLoaded: action,

      appSettings: observable,
      userSettings: computed,
      updatePageSetting: action,

      // Global parameters
      appLoaded: observable,
      setAppLoaded: action,
      states: observable,
      platformTypes: observable,
      setOrgPlatformStates: action,

      closeTaskTrigger: observable,
      setCloseTaskTrigger: action,
      isUnAuthorizedError: observable,
      setIsUnAuthorizedError: action,
      apiCriticalIssue: observable,
      setApiCriticalIssue: action,

      organizationName: observable,
      organizationLogo: observable,
      organizationHelpLink: observable,
      updateLogoHelpLink: action,

      lookupsInfo: observable,
      fetchLookupsInfo: action,

      isRefresh: observable,
      setRefresh: action,

      workflowConfigList: observable,
      workflowTreeList: observable,
      loadWorkflowConfigList: action,
    });

    this.routerStore = new RouterStore();
    this.loadWorkflowConfigList();
  }

  // NOTE: Update history profile

  loadAppSettings() {
    this.appSettings = getAdminAppSettings();
  }

  get userSettings() {
    return this.appSettings[this.currentUser?.id] ?? {};
  }

  getPageSettings(pagePath) {
    return this.userSettings[pagePath] ?? {};
  }

  saveAppSettings = (value) => {
    this.appSettings = value;
    saveAdminSettings(value);
  };

  updatePageSetting = (pagePath, param) => {
    const newSettings = {
      ...this.appSettings,
      [this.currentUser.id]: {
        ...this.userSettings,
        [pagePath]: { ...this.getPageSettings(pagePath), ...param },
      },
    };
    this.saveAppSettings(newSettings);
  };

  async setUrlParams(page, urlParams) {
    if (!this.appLoaded) return;

    if (!isEqual(urlParams, this.prevUrlParams[page])) {
      try {
        this.updatePageSetting(page, { path: urlParams });
        this.prevUrlParams[page] = urlParams;
      } catch (err) {
        setError(err, false, NotificationText.updateProfileLastLogError);
      }
    }
  }

  setLastUrlLoaded(pageType) {
    this.lastUrlLoaded[pageType] = true;
  }

  // NOTE: Login
  async login(accessToken) {
    saveToken(accessToken);
    this.fetchUser();
  }

  getFullName(user) {
    return `${user.first} ${user.last}`;
  }

  getUnknownUser(name) {
    return { label: name, type: AssignType.teal };
  }

  // NOTE: User manage
  setUser(user) {
    this.currentUser = { ...user };
  }

  setUserEmailEnabled(value) {
    this.currentUser.emailsEnabled = value;
  }

  get isWFLAdminUser() {
    return (
      this.organizationId === 272 &&
      this.currentUser &&
      (this.currentUser.isAdmin ||
        ['ivan cheng', 'jesse wright', 'eric grecko'].includes(
          this.currentUser.name?.toLowerCase(),
        )) //  Super admin
    );
  }

  get ownerOptions() {
    return this.assignOptions;
  }

  async fetchOrganizationInfo(organizationId) {
    try {
      if (organizationId) {
        const organizationInfo = await organizationApi.getOrganization(organizationId);

        runInAction(() => {
          this.updateLogoHelpLink(
            organizationInfo.profile?.branding?.logoUrl,
            organizationInfo.profile?.branding?.helpUrl,
          );
          this.organizationName = organizationInfo.name;
        });
      }
    } catch (err) {
      setError(err, false, "Can't fetch organization info");
    }
  }

  async fetchLookupsInfo(orgId) {
    try {
      if (this.lookupsInfo?.organizationId === orgId) return;
      const lookupsInfo = await organizationApi.getOrgLookups(orgId);
      runInAction(() => {
        const { states, platforms } = lookupsInfo;
        this.lookupsInfo = lookupsInfo;
        userStore.setOrgPlatformStates(states, platforms);
      });
    } catch (err) {
      setError(err, false, "Can't fetch lookups info");
    }
  }

  async fetchUser() {
    try {
      const me = await userApi.getMyProfile();
      await this.fetchOrganizationInfo(me.organizationId);
      runInAction(() => {
        this.currentUser = {
          ...me,
          type: this.members.find((user) => user.id === me.id)?.type,
        };
        this.organizationId = this.currentUser.organizationId;
        this.loadAppSettings();
        return this.currentUser;
      });
    } catch (err) {
      setError(err, true);
    }
  }

  async setAssignees(assigns) {
    this.members = sortMembers(assigns);
    this.assignOptions = this.members.map(mapToUserOption);
  }

  updateName(firstName, lastName) {
    this.currentUser.first = firstName;
    this.currentUser.last = lastName;
    this.currentUser.name = `${firstName} ${lastName}`;
  }

  setRefresh() {
    this.isRefresh = !this.isRefresh;
  }

  updateLogoHelpLink(logoUrl, helpLink) {
    this.organizationLogo = logoUrl || DEFAULT_BRANDING_INFO.logoUrl;
    this.organizationHelpLink = helpLink || DEFAULT_BRANDING_INFO.helpUrl;
  }

  setOrgPlatformStates(states, platformTypes) {
    this.states = sortBy(states, 'name').map((state) => mapToStatusOption(state));
    this.platformTypes = platformTypes;
  }

  setAppLoaded() {
    this.appLoaded = true;
  }

  setCloseTaskTrigger() {
    this.closeTaskTrigger = !this.closeTaskTrigger;
  }

  setIsUnAuthorizedError() {
    this.isUnAuthorizedError = true;
  }

  setApiCriticalIssue(issue) {
    this.apiCriticalIssue = issue;
  }

  loadWorkflowConfigList() {
    const { workflowConfigList, workflowTreeList } = getWorkflowConfigList();
    this.workflowConfigList = workflowConfigList;
    this.workflowTreeList = workflowTreeList;
  }

  dispose() {
    this.routerStore.dispose();
  }
}
/* Store end */

export const userStore = new UserStore();
