import { DataGridTable, Typography } from '@/components';
import { TRIGGERS_COLUMNS } from '../tableColumns';

import styles from './TriggersTable.module.css';

export default function TriggersTable({ rows }) {
  return (
    <>
      <Typography variant="subtitle2" className={styles.title}>
        Triggered:
      </Typography>
      {!rows?.length ? (
        <Typography variant="body2" className={styles.indent}>
          No tickets
        </Typography>
      ) : (
        <DataGridTable
          rows={rows}
          columns={TRIGGERS_COLUMNS}
          hideFooter
          className={styles.indent}
        />
      )}
    </>
  );
}
