import { observer } from 'mobx-react-lite';
import { IconButton } from '@mui/material';
import { Cached } from '@mui/icons-material';
import cx from 'clsx';

import { InfoRow, OrgAccessDropdown, TextField, TriStateCheckbox, Typography } from '@/components';
import { isValidateEmail, showErrorNotification } from '@/utils';
import { AnchorOrigin } from '@/utils/types';
import { CopyOutlineIcon } from '@/assets/icons';
import { userApi } from '@/api';
import { useSettingsStore } from '@/store';

import styles from './DetailContent.module.css';

const PAGE_NAME = 'UserContent';

const UserContent = ({ isEditingMode, setIsSaving, isDeveloperSettings, showError }) => {
  const { membersStore } = useSettingsStore();
  const {
    organizationId,
    first: firstName,
    last: lastName,
    email,
    authId,
    accessId,
    organizationPathByName,
    createdOn,
    enabled,
    secret,
  } = membersStore.selectedUsers[0];
  const { isActive, isAdmin, isAssignable, isContributor, emailsEnabled } = membersStore.unionInfo;
  const isMultiple = membersStore.selectedUsers.length > 1;

  // useEffect(() => setIsRefresh((prev) => !prev), [membersStore.selectedUsers]);

  const handleChangeMemberInfo = (fieldValue) => {
    if (isMultiple) {
      membersStore.updateUnionInfo(fieldValue);
    } else {
      membersStore.updateSelectedUser(fieldValue);
    }
  };

  const handleCopyText = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleChangeEmailEnabled = (emailsEnabled) => {
    if (isMultiple) {
      membersStore.updateUnionInfo({ emailsEnabled });
    } else {
      membersStore.updateSelectedUser({
        profile: { ...membersStore.firstSelectedUser.profile, emailsEnabled },
      });
    }
  };

  const handleRefreshAccess = async () => {
    setIsSaving(true);
    try {
      const access = await userApi.refreshAPIAccess(membersStore.firstSelectedUser.id);
      handleChangeMemberInfo(access);
    } catch (err) {
      showErrorNotification(err.preview ?? err.message);
    }
    setIsSaving(false);
  };

  return (
    <>
      {!isDeveloperSettings && (
        <>
          {!isMultiple && isEditingMode && (
            <InfoRow label="Name">
              <div className={cx(styles.row, styles.fullName)}>
                <TextField
                  key="First Name"
                  placeholder="First Name"
                  value={firstName}
                  error={showError && !firstName}
                  onChange={(e) => handleChangeMemberInfo({ first: e.target.value })}
                  id={`${PAGE_NAME}_newMember_firstName`}
                  disabled={!isEditingMode}
                />
                <TextField
                  key="Last Name"
                  placeholder="Last Name"
                  value={lastName}
                  error={showError && !lastName}
                  onChange={(e) => handleChangeMemberInfo({ last: e.target.value })}
                  id={`${PAGE_NAME}_newMember_lastName`}
                />
              </div>
            </InfoRow>
          )}
          {!isMultiple && (
            <>
              <InfoRow label="Organization">
                {!isEditingMode && (
                  <Typography variant="body2" className={styles.path}>
                    {organizationPathByName}
                  </Typography>
                )}
                {isEditingMode && (
                  <OrgAccessDropdown
                    value={organizationId}
                    anchorPosition={AnchorOrigin.bottom}
                    error={showError && !organizationId}
                    className={styles.orgDropdown}
                    onChange={(organizationId, organizationPathByName) =>
                      handleChangeMemberInfo({
                        organizationId,
                        organizationPathByName,
                      })
                    }
                    style={{ width: '100%' }}
                  />
                )}
              </InfoRow>
              <InfoRow label="Work Email">
                <TextField
                  key="Work Email"
                  placeholder="Work Email"
                  value={email}
                  error={showError && !isValidateEmail(email)}
                  className={styles.inputField}
                  onChange={(e) => handleChangeMemberInfo({ email: e.target.value })}
                  id={`${PAGE_NAME}_newMember_workEmail`}
                  disabled={!isEditingMode}
                />
              </InfoRow>
              <InfoRow label="Created">
                <Typography variant="body2">{createdOn}</Typography>
              </InfoRow>
              <InfoRow label="AuthId">
                <Typography variant="body2">{authId}</Typography>
              </InfoRow>
            </>
          )}
          <InfoRow label="Is Active">
            <TriStateCheckbox
              triState={isMultiple}
              checked={isActive}
              onChange={(value) => handleChangeMemberInfo({ isActive: value })}
              disabled={!isEditingMode}
            />
          </InfoRow>
          <InfoRow label="Is Admin">
            <TriStateCheckbox
              triState={isMultiple}
              checked={isAdmin}
              onChange={(value) => handleChangeMemberInfo({ isAdmin: value })}
              disabled={!isEditingMode}
            />
          </InfoRow>
          <InfoRow label="Is Assignable">
            <TriStateCheckbox
              triState={isMultiple}
              checked={isAssignable}
              onChange={(value) => handleChangeMemberInfo({ isAssignable: value })}
              disabled={!isEditingMode}
            />
          </InfoRow>
          <InfoRow label="Is Contributor">
            <TriStateCheckbox
              triState={isMultiple}
              checked={isContributor}
              onChange={(value) => handleChangeMemberInfo({ isContributor: value })}
              disabled={!isEditingMode}
            />
          </InfoRow>
          <InfoRow label="Email Enabled">
            <TriStateCheckbox
              triState={isMultiple}
              checked={emailsEnabled}
              disabled={!isEditingMode}
              onChange={(value) => handleChangeEmailEnabled(value)}
            />
          </InfoRow>
        </>
      )}
      {!isMultiple && isDeveloperSettings && (
        <div style={{ height: 92 }}>
          {enabled && (
            <>
              <InfoRow label="AccessId">
                <TextField value={accessId} sx={{ flex: 1 }} inactive disabled />
                <IconButton id={`${PAGE_NAME}_copyButton`} onClick={() => handleCopyText(accessId)}>
                  <CopyOutlineIcon fontSize="small" />
                </IconButton>
                <div style={{ width: 30 }} />
              </InfoRow>
              <InfoRow label="Secret">
                <TextField value={secret} sx={{ flex: 1 }} inactive disabled />
                <IconButton id={`${PAGE_NAME}_copyButton`} onClick={() => handleCopyText(accessId)}>
                  <CopyOutlineIcon fontSize="small" />
                </IconButton>
                <IconButton id={`${PAGE_NAME}_refreshButton`} onClick={handleRefreshAccess}>
                  <Cached fontSize="small" className={styles.icon} />
                </IconButton>
              </InfoRow>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default observer(UserContent);
