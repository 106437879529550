import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import moment from 'moment-timezone';
import { omit } from 'lodash';

import { attachmentApi, organizationApi, ticketApi } from '@/api';
import { userStore } from '@/store';
import {
  formatLocalDateTime,
  formatUTCDateTime,
  getNodeItemFromId,
  isValidQuantity,
  mapToUserOption,
  showErrorNotification,
  showSuccessNotification,
  sortMembers,
} from '@/utils';
import { setError } from '@/utils/errors';
import { NotificationText, PriorityOptions } from '@/utils/types';
import { WORKFLOW_CONFIGS } from '@/utils/workflows';
import { ATTACHMENTS_FILED } from '@/utils/constants';

export class CreateTaskStore {
  isLoading = false;
  isCreateMode = false;
  parentId = null;
  organizationInfo = {};
  createTicket = {};
  assignOptions = [];
  workflowId = null;
  showForm = false;

  constructor() {
    makeObservable(this, {
      isCreateMode: observable,
      setCreateMode: action,
      showForm: observable,
      setShowForm: action,

      createTicket: observable,
      updateData: action,
      setQuantity: action,
      reset: action,

      organizationInfo: observable,
      organizationId: computed,
      assignOptions: observable,
      fetchOrganization: action,

      workflowId: observable,
      setWorkflow: action,
      workflowInfo: computed,
      workflowConfig: computed,
      selectedWorkflowType: computed,
      isQuestion: computed,

      isLoading: observable,
      setIsLoading: action,
    });
  }

  get workflowInfo() {
    return userStore.workflowConfigList.find(({ id }) => id === this.workflowId);
  }

  get selectedWorkflowType() {
    return this.workflowInfo?.workflow;
  }

  get isQuestion() {
    return this.workflowInfo?.name?.includes('Question');
  }

  setWorkflow(workflowId) {
    this.workflowId = workflowId;
    this.createTicket.workflow = getNodeItemFromId(
      userStore.workflowTreeList,
      workflowId,
    )?.workflow;
    this.createTicket.config = WORKFLOW_CONFIGS[this.createTicket.workflow]?.template;
  }

  updateData(fieldValue) {
    this.createTicket = { ...this.createTicket, ...fieldValue };
  }

  async setCreateMode(orgId, ticketId) {
    this.isCreateMode = Boolean(orgId);
    this.parentId = ticketId;
    if (this.organizationId !== orgId) {
      await this.fetchOrganization(orgId);
    }

    if (orgId) {
      this.reset();
    }
  }

  get unassigned() {
    return this.assignOptions.find((user) => user.label === 'Unassigned')?.value;
  }

  get workflowConfig() {
    return this.createTicket.workflow;
  }

  setShowForm(value) {
    this.showForm = value;
  }

  reset() {
    this.createTicket = {
      organizationId: this.organizationId,
      title: '',
      description: '',
      priority: PriorityOptions[0].value,
      ownedBy: userStore.currentUser.id,
      assignedTo: this.unassigned,
      config: {},
      attachedFiles: [],
    };
  }

  get organizationId() {
    return this.organizationInfo?.id;
  }

  setQuantity(value) {
    this.createTicket.quantity = Number(value);
    const baseDays = this.workflowInfo?.baseDays;
    if (isValidQuantity(value) && baseDays) {
      const estimatedDays = (1 + Math.log10(value)) * baseDays;
      const estimatedDate = moment().add(estimatedDays, 'd');
      this.createTicket.estimatedTime = formatLocalDateTime(estimatedDate);
    }
  }

  // NOTE: Submit
  async submitTicket(workflow, workflowConfigData, templateFile) {
    let result = null;
    try {
      const configAttachments = WORKFLOW_CONFIGS[workflow]?.attachments;
      let config = workflowConfigData;
      this.setIsLoading(true);
      if (configAttachments?.length) {
        if (templateFile) {
          const attachedNameFields = await Promise.all(
            configAttachments.map(async (attachedNameField) => {
              const file = templateFile[attachedNameField];
              const filename = await attachmentApi.uploadAttachment(
                file,
                `${attachedNameField}_${file?.name}`,
              );
              return { [attachedNameField]: filename };
            }),
          );

          config = attachedNameFields.reduce(
            (acc, field) => ({ ...acc, ...field }),
            workflowConfigData,
          );
        } else {
          config = workflowConfigData;
        }
      }
      const payloadData = omit(
        {
          organizationId: this.createTicket.organizationId,
          parentId: this.parentId,
          title: this.createTicket.title,
          description: this.createTicket.description,
          priority: Number(this.createTicket.priority),
          quantity: Number(this.createTicket.quantity),
          ownedBy: Number(this.createTicket.ownedBy),
          assignedTo: Number(this.createTicket.assignedTo),
          estimatedCompletion: formatUTCDateTime(this.createTicket.estimatedTime ?? new Date()),
          workflow,
          config,
        },
        [!this.parentId && 'parentId'],
      );
      const ticketId = await ticketApi.createTicket(payloadData);
      if (this.createTicket.attachedFiles.length > 0) {
        const formData = new FormData();
        formData.append('comment', `[TicketId=${ticketId}]`);
        this.createTicket.attachedFiles.forEach((file) => {
          formData.append(ATTACHMENTS_FILED, file);
        });
        await ticketApi.addComment(ticketId, formData);
      }

      runInAction(() => {
        showSuccessNotification(NotificationText.createRequestSuccess);
        result = ticketId;
      });
    } catch (err) {
      setError(err, false, NotificationText.createdError);
      showErrorNotification(NotificationText.createdError);
      result = null;
    }
    this.setIsLoading(false);
    return result;
  }

  async fetchOrganization(orgId) {
    if (!orgId) return;
    this.setIsLoading(true);
    try {
      await userStore.fetchLookupsInfo(orgId);
      const organizationInfo = await organizationApi.getOrganization(orgId);
      this.organizationInfo = organizationInfo;
      const assigns = sortMembers(userStore.lookupsInfo.assignees);
      this.assignOptions = assigns.map(mapToUserOption);
    } catch (err) {
      setError(err);
      showErrorNotification(NotificationText.detailPageFetchError);
    }
    this.setIsLoading(false);
  }

  setIsLoading(value) {
    this.isLoading = value;
  }

  dispose() {}
}
