import { makeObservable, action, observable, computed } from 'mobx';
import { omit } from 'lodash';
import { WORKFLOW_CONFIGS } from '@/utils/workflows';
import { ServiceTypes } from '@/utils/types';
import { getWorkflowAttachments } from '@/utils/staticForm';

export class WorkflowStore {
  isEditing = false;
  createStore = null;
  detailStore = null;
  workflowSchemaData = null;
  templateDescription = '';
  templateFiles = {};
  specificData = null;
  errorMessage = '';
  serviceType = ServiceTypes.single;
  skipExecution = false;
  generateWeeksOfCoverage = false;
  staticFormValue = { value: null, isValid: false };
  showForm = true;

  constructor(detailStore, createStore) {
    makeObservable(this, {
      templateFiles: observable,
      setTemplateFiles: action,
      setConfigAttachments: action,
      specificData: observable,
      errorMessage: observable,

      workflow: computed,
      isValidTemplate: computed,
      template: computed,

      reset: action,
      clearTemplateFile: action,
      setIsLoading: action,

      isEditing: observable,
      setIsEditing: action,

      staticFormValue: observable,
      updateStaticFormValue: action,
      updateSpecificData: action,

      serviceType: observable,
      isSingleServiceType: computed,
      setServiceType: action,

      skipExecution: observable,
      setSkipExecution: action,

      generateWeeksOfCoverage: observable,
      setGenerateWeeksOfCoverage: action,

      showForm: observable,
      setShowForm: action,
    });

    this.detailStore = detailStore;
    this.createStore = createStore;
  }

  clearTemplateFile(field) {
    if (field) this.templateFiles = omit(this.templateFiles, field);
    else this.templateFiles = {};
    this.specificData = null;
  }

  reset() {
    this.isEditing = false;
    this.clearTemplateFile();
    this.isTemplateConfigured = ServiceTypes.single;
    this.skipExecution = false;
    this.generateWeeksOfCoverage = false;
    this.staticFormValue = { value: null, isValid: false };
    this.specificData = null;
  }

  reload(config) {
    this.reset();
    const configAttachments = getWorkflowAttachments(this.workflow, config);
    this.setSkipExecution(config?.SkipExecution);
    this.setConfigAttachments(configAttachments);
  }

  setTemplateFiles(field, file) {
    this.templateFiles[field] = file;
  }

  setConfigAttachments(templateFile) {
    this.templateFiles = templateFile ?? {};
  }

  get isValidTemplate() {
    const attachments = WORKFLOW_CONFIGS[this.workflow]?.attachments;
    return attachments?.every((field) => Boolean(this.templateFiles[field]));
  }

  setIsLoading(value) {
    this.detailStore.setIsLoading(value);
  }

  updateStaticFormValue(value, isValid) {
    this.staticFormValue = { value, isValid };
  }

  updateSpecificData(field, value) {
    if (!this.specificData) this.specificData = { [field]: value };
    else this.specificData[field] = value;
  }

  get workflow() {
    if (this.createStore.showForm) return this.createStore.workflowConfig;
    return this.detailStore.workflowConfig;
  }

  get template() {
    return WORKFLOW_CONFIGS[this.workflow]?.template;
  }

  setIsEditing(value) {
    this.isEditing = value;
  }

  setServiceType(value) {
    this.serviceType = value;
  }

  get isSingleServiceType() {
    return this.serviceType === ServiceTypes.single;
  }

  setSkipExecution(value) {
    this.skipExecution = value;
  }

  setGenerateWeeksOfCoverage(value) {
    this.generateWeeksOfCoverage = value;
  }

  setShowForm(value) {
    this.showForm = value;
  }

  async submitWorkflow(workflowType) {
    const templateFile = !this.isSingleServiceType ? this.templateFiles : null;

    const ticketId = await this.createStore.submitTicket(
      workflowType,
      {
        ...(this.isSingleServiceType ? this.staticFormValue.value : this.specificData),
        SkipExecution: this.skipExecution,
        GenerateWeeksOfCover: this.generateWeeksOfCoverage,
      },
      templateFile,
    );
    if (ticketId) {
      this.reset();
    }
    return { ticketId };
  }

  dispose() {
    this.accountStore.dispose();
  }
}
