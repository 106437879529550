import { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Divider } from '@mui/material';

import {
  AmazonStatus,
  Avatar,
  Button,
  CollapseButton,
  CompactInfo,
  IconButton,
  LoadingIndicator,
  MultiLineInput,
  ResizeGroupPanel,
  TreeListDropdown,
  Typography,
} from '@/components';
import { InformationPanel } from '../InformationPanel';
import { useMainStore } from '../../stores/useMainStore';
import { DetailWorkflowContent } from '../DetailWorkflowContent';
import ScheduleTable from '../ScheduleTable';
import TriggersTable from '../TriggersTable';
import { CommentContent } from '../CommentContent';
import { AutomationLog } from '@/components/Table/AutomationLog';
import { BASE_ROUTES, ObjectType, UrlSearch } from '@/utils/types';
import {
  getHyperText,
  getTicketLink,
  TICKET_DETAIL_RESIZE_SAVE_KEY,
  TICKET_TREE_RESIZE_SAVE_KEY,
} from '@/utils';
import { TicketTree } from '../TicketTree';
import { StatusSelect } from '../StatusSelect';

import styles from './DetailTicketContent.module.css';
import { userStore } from '@/store';
import { AvatarTooltip } from '@/components/Avatar/AvatarTooltip';
import { PAGE_MODE } from '@/utils/constants';
import { Close } from '@mui/icons-material';

const PAGE_NAME = 'DetailTicketContent';

export const DetailTicketContent = observer(({ onClose }) => {
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const { detailStore, createTaskStore, workflowStore, routerStore } = useMainStore();
  const { isEditing, currentTicket, parsedTicket: ticket, isShowTicket } = detailStore;
  const open = isShowTicket;
  const {
    ticketId,
    title,
    workflow,
    organization,
    organizationId,
    state,
    externalStatus,
    assignee,
    automation,
    objectType,
    description,
  } = currentTicket;
  const { description: hideDescription, schedules: hideSchedules } = detailStore.isCollapse;
  const hyperText = useMemo(() => getHyperText(description || ''), [description]);

  const updateData = (fieldValue) => detailStore.updateTicketField(fieldValue);
  const isViewPage = Boolean(ticketId);

  const onCancel = useCallback(() => {
    detailStore.reloadCurrentTicket();
    workflowStore.reload(ticket?.config);
    detailStore.setPageMode(PAGE_MODE.none);
  }, [ticket]);

  const handleSaveClicked = async () => {
    if (!detailStore.isEditing) {
      detailStore.reloadCurrentTicket();
      detailStore.setPageMode(PAGE_MODE.editing);
    } else {
      setSaveButtonClicked(true);
      const { title, estimatedCompletion, objectType, remainingWork } = currentTicket;
      if (
        (objectType === ObjectType.question && title) ||
        (title && (objectType === ObjectType.task || remainingWork) && estimatedCompletion)
      ) {
        const newData = {
          ...currentTicket,
          config: {
            ...currentTicket.config,
            ...(workflowStore.isSingleServiceType
              ? workflowStore.staticFormValue.value
              : workflowStore.specificData),
            SkipExecution: workflowStore.skipExecution,
          },
        };
        setSaveButtonClicked(false);
        detailStore.setPageMode(PAGE_MODE.none);
        await detailStore.updateTicket(newData, workflowStore.templateFiles);
        detailStore.reloadCurrentTicket();
      }
    }
  };

  const onActiveTicket = useCallback(
    (ticket) => {
      if (detailStore.activeTicketId !== ticket.id) {
        routerStore.setSearchParam(UrlSearch.activeTicket, ticket.id);
        detailStore.setActiveTicketId(ticket.id);
      }
    },
    [detailStore.activeTicketId],
  );

  if (!open) return null;

  return (
    <>
      {detailStore.isLoading && !detailStore.familyTickets?.length && <LoadingIndicator />}
      {detailStore.familyTickets?.length > 0 && (
        <ResizeGroupPanel
          direction="horizontal"
          saveId={TICKET_TREE_RESIZE_SAVE_KEY}
          id={`${PAGE_NAME}_container`}
          firstPanel={
            <DndProvider backend={HTML5Backend}>
              <TicketTree
                data={detailStore.familyTickets}
                ticketId={detailStore.activeTicketId}
                onActiveTicket={onActiveTicket}
              />
            </DndProvider>
          }
          showFirstPanel={isViewPage}
        >
          {detailStore.isLoading && <LoadingIndicator />}
          {!detailStore.isLoading && (
            <>
              <div className={styles.header}>
                <div className={styles.titleWrapper}>
                  <Typography variant="subtitle1">
                    <a href={getTicketLink(ticketId)} target="_blank" rel="noopener noreferrer">
                      {ticketId}
                    </a>
                  </Typography>
                  {isEditing ? (
                    <MultiLineInput
                      id={`${PAGE_NAME}_title_multiLineInput`}
                      placeholder="Provide a title for the task"
                      size="small"
                      error={saveButtonClicked && !title}
                      value={title}
                      maxLength={160}
                      onChange={(value) => updateData({ title: value })}
                      style={{ flex: 1 }}
                    />
                  ) : (
                    <Typography variant="subtitle1" className={styles.truncatedText}>
                      {title}
                    </Typography>
                  )}
                  <AutomationLog automation={automation} isAdminUser />
                </div>
                <div id={`${PAGE_NAME}_header`} className={styles.buttonsGroup}>
                  <Button
                    id={`${PAGE_NAME}_save_button`}
                    size="small"
                    variant="secondary"
                    onClick={() => {
                      workflowStore.reset();
                      createTaskStore.setCreateMode(organizationId, ticketId);
                    }}
                  >
                    Create
                  </Button>
                  <Button
                    id={`${PAGE_NAME}_save_button`}
                    size="small"
                    variant="secondary"
                    onClick={handleSaveClicked}
                  >
                    {detailStore.isEditing ? 'Save' : 'Edit'}
                  </Button>
                  {detailStore.isEditing && (
                    <Button
                      id={`${PAGE_NAME}_cancel_button`}
                      size="small"
                      variant="tertiary"
                      onClick={onCancel}
                    >
                      Cancel
                    </Button>
                  )}
                  <IconButton
                    id="btn-export-csv"
                    size="small"
                    tooltipArrow
                    tooltipTitle="Close the panel"
                    onClick={onClose}
                  >
                    <Close fontSize="small" color="primary" />
                  </IconButton>
                </div>
              </div>
              <div className={styles.tabHeader}>
                <Typography variant="subtitle2">{workflow}</Typography>
                <Typography variant="body2">
                  <a
                    href={`${BASE_ROUTES.organizations}?${UrlSearch.organization}=${organizationId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {organization}
                  </a>
                </Typography>
                {isEditing && (
                  <StatusSelect
                    id={`${PAGE_NAME}_statusSelect`}
                    value={state}
                    options={userStore.states}
                    onChange={(value) => updateData({ state: value })}
                    isDarkTheme
                    disabled={!isEditing}
                  />
                )}
                {!isEditing && <AmazonStatus id={`${PAGE_NAME}_status`} value={state} />}
                {objectType === ObjectType.case && externalStatus && (
                  <AmazonStatus id={`${PAGE_NAME}_externalStatus`} value={externalStatus} />
                )}
                {!isEditing && (
                  <Avatar
                    name={assignee?.label}
                    width={20}
                    variant={assignee?.type}
                    className={styles.assign}
                    tooltipArrow={false}
                    tooltipText={<AvatarTooltip name={assignee?.name} companyName={organization} />}
                  />
                )}
              </div>
              {isViewPage && (
                <div className={styles.taskContent}>
                  <ResizeGroupPanel
                    saveId={TICKET_DETAIL_RESIZE_SAVE_KEY}
                    direction="horizontal"
                    firstPanel={
                      <>
                        <Typography variant="caption-bold" className={styles.buttonsGroup}>
                          Description
                          <CollapseButton
                            isCollapse={hideDescription}
                            onClick={() =>
                              detailStore.setIsCollapse({ description: !hideDescription })
                            }
                          />
                        </Typography>
                        {!hideDescription && (
                          <CompactInfo>
                            {!isEditing && description && (
                              <Typography variant="body2" multiline sx={{ flex: 1 }}>
                                <div dangerouslySetInnerHTML={{ __html: hyperText }} />
                              </Typography>
                            )}
                            {isEditing && (
                              <MultiLineInput
                                id={`${PAGE_NAME}_description_multiLineInput`}
                                placeholder="Provide a description of the task"
                                value={description}
                                size="small"
                                onChange={(value) => updateData({ description: value })}
                                style={{ flex: 1 }}
                              />
                            )}
                          </CompactInfo>
                        )}
                        <Divider />
                        <DetailWorkflowContent
                          content={currentTicket.config}
                          workflowName={detailStore.workflowConfig}
                          isEditing={isEditing}
                        />
                        <Divider />
                        <ScheduleTable />
                        {!hideSchedules && <TriggersTable rows={detailStore.triggeredTickets} />}
                        <Divider />
                        <CommentContent />
                      </>
                    }
                    initialFirstSize={80}
                    classes={{
                      root: styles.detailsRoot,
                      firstPanel: styles.panel,
                      lastPanel: styles.panel,
                    }}
                  >
                    <InformationPanel
                      variant={objectType}
                      data={currentTicket}
                      updateData={updateData}
                      showError={saveButtonClicked}
                    />
                  </ResizeGroupPanel>
                </div>
              )}
            </>
          )}
        </ResizeGroupPanel>
      )}
    </>
  );
});
