import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { cloneDeep } from 'lodash';

import OrganizationContent from '../OrganizationContent';
import { Button, LoadingIndicator, TextLink, Typography } from '@/components';
import { DEFAULT_ORGANIZATION, PAGE_MODE } from '@/utils/constants';
import { useSettingsStore, userStore } from '@/store';
import {
  getOrganizationInfo,
  isValidWebLink,
  showErrorNotification,
  showSuccessNotification,
} from '@/utils';
import { getValidPlatformLogin } from '@/utils/staticForm';
import { setError } from '@/utils/errors';
import { BASE_ROUTES, UrlSearch } from '@/utils/types';

import styles from './DetailContent.module.css';

const PAGE_NAME = 'DetailContent';

const DetailContent = ({ open, pageMode, setPageMode }) => {
  const { organizationStore, accountStore, membersStore } = useSettingsStore();
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
  const [orgInfo, setOrgInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isViewMode = pageMode === PAGE_MODE.none || pageMode === PAGE_MODE.loading;
  const selectedOrg = accountStore.selectedAccount;

  useEffect(() => {
    const fetchOrganization = async () => {
      setIsLoading(true);
      try {
        organizationStore.setParentOrgId(selectedOrg?.parentId);
        await organizationStore.fetchOrganizationInfo(selectedOrg?.id);
        setPageMode(PAGE_MODE.none);
        setOrgInfo(getOrganizationInfo(organizationStore.organizationInfo));
      } catch (err) {
        setError(err, true);
        showErrorNotification(err.preview ?? err.message);
      }
      setIsLoading(false);
    };
    if (pageMode === PAGE_MODE.creating) {
      organizationStore.setOrganizationInfo(DEFAULT_ORGANIZATION);
      organizationStore.setParentOrgId(selectedOrg?.id ?? userStore.organizationId);
      setOrgInfo(DEFAULT_ORGANIZATION);
    } else if (pageMode === PAGE_MODE.loading) {
      fetchOrganization();
    }
  }, [selectedOrg, pageMode]);

  const handleSaveOrganization = async () => {
    if (pageMode === PAGE_MODE.none) {
      setPageMode(PAGE_MODE.editing);
      return;
    }
    setIsSaveButtonClicked(true);
    const {
      name,
      profile: { branding = {}, eventSubscriptions = [], logins, settings = {}, services = [] },
    } = orgInfo;

    if (
      name &&
      (!branding.helpUrl || isValidWebLink(branding.helpUrl)) &&
      logins.every((platformLogin) => getValidPlatformLogin(platformLogin)) &&
      !eventSubscriptions?.some(({ event, link }) => !event || !isValidWebLink(link))
    ) {
      setIsLoading(true);
      const result = await organizationStore.saveOrganizationInfo(
        selectedOrg.id,
        name,
        branding,
        eventSubscriptions,
        logins,
        settings?.isCaptcha,
        pageMode === PAGE_MODE.creating,
        membersStore.getAccountFromId(membersStore.allAccounts, organizationStore.parentOrgId),
        services,
      );
      if (result) {
        const result = await accountStore.fetchAccounts();
        if (result) membersStore.fetchAllAccounts(cloneDeep(result));
        let organizationInfo = null;
        if (pageMode === PAGE_MODE.creating) {
          organizationInfo = accountStore.getAccountFromName(accountStore.allAccounts, name);
          if (organizationInfo?.id) accountStore.onSelectAccount(organizationInfo?.id);
        } else {
          accountStore.onSelectAccount(orgInfo.id);
        }
        setIsSaveButtonClicked(false);
        setPageMode(PAGE_MODE.loading);
        showSuccessNotification('Organization successfully saved!');
      }
      setIsLoading(false);
    }
  };

  return (
    <div id={`${PAGE_NAME}_container`} className={styles.detailContentContainer}>
      {open && isLoading && <LoadingIndicator />}
      {open && Boolean(orgInfo) && !isLoading && (
        <div className={styles.taskContent}>
          <div className={styles.organizationHeader}>
            <div className={styles.titleWrapper}>
              <Typography variant="h6" className={styles.truncatedText}>
                {pageMode === PAGE_MODE.creating ? orgInfo.name : `${orgInfo.id}: ${orgInfo.name}`}
              </Typography>
              <TextLink
                href={`${BASE_ROUTES.main}?${UrlSearch.organization}=${selectedOrg?.id}`}
                className={styles.linkButton}
              >
                To Tickets
              </TextLink>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                size="small"
                variant="secondary"
                onClick={handleSaveOrganization}
                id={`${PAGE_NAME}_saveButton`}
              >
                {isViewMode ? 'Edit' : 'Save'}
              </Button>
              {!isViewMode && (
                <Button
                  size="small"
                  variant="tertiary"
                  onClick={() => {
                    setOrgInfo(getOrganizationInfo(organizationStore.organizationInfo));
                    setPageMode(PAGE_MODE.none);
                    setIsSaveButtonClicked(false);
                  }}
                  id={`${PAGE_NAME}_cancelButton`}
                >
                  Cancel
                </Button>
              )}
            </div>
          </div>
          <OrganizationContent
            pageMode={pageMode}
            orgInfo={orgInfo}
            setOrgInfo={setOrgInfo}
            showError={isSaveButtonClicked}
          />
        </div>
      )}
    </div>
  );
};

export default observer(DetailContent);
